import React from "react"
import Layout from "../templates/layout"
import SEO from "../components/seo/seo"
import Hero from "../components/hero/hero"
import CircleCta from "../components/circleCta/circleCta"
import IconWithText from "../components/iconWithText/iconWithText"
import HaveQuestions from "../components/haveQuestions/haveQuestions"
import ElitekLogo from "../images/logos/elitek-logo.svg"
import JevtanaLogo from "../images/logos/jevtana-logo.svg"
import SarclisaLogo from "../images/logos/sarclisa-logo-new.svg";
import AffordIcon from "../images/icons/icon-afford.svg"
import AccessIcon from "../images/icons/icon-access.svg"
import FormsIcon from "../images/icons/icon-form-pen.png"
import QuestionsIcon from "../images/icons/icon-questions.svg"
import "../styles/pages/pages.scss"
import BackToTop from "../components/backToTop/BackToTop"
import PrescribingInfo from "../components/prescribingInfo/prescribingInfo"

const showVideoModal = () => {
  const modal = document.querySelector('#video-overlay');
  // showing the video modal
  modal.classList.remove('hide');
  document.querySelector('html').style.overflow = 'hidden';
}

const pageContent = () => (

    <Layout pageid="page-home" pageClass="hcp-home">
      <SEO
      title="CareASSIST for eligible patients prescribed Sanofi medications | HCP Site"
      keywords="CareASSIST, Sanofi, HCP Site"
      description="Info for providers on CareASSIST by Sanofi, including financial assistance, enrollment, access & reimbursement, and resource support"
      />

      <Hero
      headline={["CareASSIST", <sup className="sup-fs-26">&reg;</sup>]}
      copy="Access and financial assistance for your eligible patients"
      heroImgClass="hero-img"  
      isExternalButton={false}
      btnLink="../pdfs/careassist-program-enrollment-form.pdf"
      btnText="Enroll"
      btnClass="light-blue download-btn mb-1 fs-16 enroll"
      videoBtn
      videoOnClick={showVideoModal}
      label={<>CareASSIST <br/>overview video</>}
      videoBtnClass="lighter-blue video-btn"  
      iconClass="enrollIcon"
      iconText="Enroll online"
      iconUrl="https://sanoficareassisthcp.iassist.com/"
      linkId="hcp-enroll-online"
      />
      <section className="content-section non-brand-homepage" id="overview">
        <h2>Our Care Managers support your patients every step of the way, including with:</h2>
        <div className="flex-section mb-0 evenly">
          <IconWithText 
          icon={AffordIcon}
          alt="Financial assistance"
          text="Financial assistance"
          link=""
          />
          <IconWithText
          icon={AccessIcon}
          alt="Access and reimbursement"
          text="Access &amp; reimbursement"
          link=""
          />
          <IconWithText 
          icon={FormsIcon}
          alt="Resource support"
          text="Resource support"
          link=""
          />
        </div>        


      </section>
      
      <section className="select-treatment">
        <h2>Select a medication to get started</h2>
        <div className="flex-section evenly">
          <CircleCta
          class="elitek"
          img={ElitekLogo}
          imgAlt="Elitek Logo"
          piInfo={<p>Please see full <a href="https://products.sanofi.us/elitek/elitek.html" id="hcp-home-elitek-prescribing-information" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING.</p>}
          btnLink="/hcp/elitek/"
          btnTitle="Meaningful title for accessibility"
          btnText="Click Here"
          btnClass="elitek"
          linkId="hcp-circlecta-elitek"
          />
          <CircleCta
          class="jevtana"
          img={JevtanaLogo}
          imgAlt="Jevtana Logo"
          piInfo={<p>Please see full <a href="https://products.sanofi.us/jevtana/jevtana.html" id="hcp-home-jevtana-prescribing-information" target="_blank" rel="noreferrer">Prescribing Information</a>, including Boxed WARNING.</p>}
          btnLink="/hcp/jevtana"
          btnTitle="Meaningful title for accessibility"
          btnText="Click Here"
          btnClass="jevtana"
          linkId="hcp-circlecta-jevtana"
          />
          <CircleCta
          class="sarclisa"
          img={SarclisaLogo}
          imgAlt="Sarclisa logo"
          piInfo={<p>Please see full <a href="https://products.sanofi.us/sarclisa/sarclisa.html" id="hcp-home-sarclisa-prescribing-information" target="_blank" rel="noreferrer">Prescribing Information</a>.</p>}
          btnLink="/hcp/sarclisa"
          btnTitle="Meaningful title for accessibility"
          btnText="Click Here"
          btnClass="sarclisa"
          linkId="hcp-circlecta-sarclisa"
          />
        </div>

      </section>

      <section className="content-section non-brand-homepage question-section" id="overview">
        <HaveQuestions 
        icon={QuestionsIcon}
        />
      </section>
      <BackToTop />
    </Layout>

)

export default pageContent